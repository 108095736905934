import React from "react"
import { PriceBar, PriceBarBanner, PriceBarButtons, PriceBarMain, PriceBarMainInfo } from "@vp/swan"
import { Guarantee } from "./Guarantee";
import { CareHelp } from "./CareHelp";
import AddToCartButton from "../add-to-cart-button/AddToCartButton";
import { PriceBarInfoDetails } from "./PriceBarInfoDetails";
import tokens from "../../styles/priceBar.scss";

export const PdcPriceBar = () => {
  return (
    <PriceBar className={tokens.pdcPriceBar}>
      <PriceBarBanner>
        <Guarantee />
        <CareHelp />
      </PriceBarBanner>
      <PriceBarMain>
          <PriceBarMainInfo>
            "Price"
            <PriceBarInfoDetails />
          </PriceBarMainInfo>
          <PriceBarButtons>
            <AddToCartButton />
          </PriceBarButtons>
        </PriceBarMain>
    </PriceBar>
  );
};
