import { REQUESTOR } from "../../constants";
import { Logger } from "@vp/ubik-logging";

const baseUrl = "https://siteflow.merch.vpsvc.com";

export interface OptionValues {
  [key: string]: string;
}

export interface SiteflowResponse {
  action: string;
  flowAction: string;
  predictedFlow: SiteflowPredictedFlow[];
  type: string;
  url: string;
  urls: string[];
}

interface SiteflowPredictedFlow {
  action: string;
  url: string;
  urls: string[];
}

export type getNextStepV2Input = {
  siteTenant: string,
  locale: string,
  productKey: string,
  productVersion: string,
  workId: string,
  experience: string,
  logger: Logger,
  optimizelyEndUserIdCookie?: string,
  selectedOptionValues?: OptionValues,
};

export async function getNextStepV2({
  siteTenant,
  locale,
  productKey,
  productVersion,
  workId,
  experience,
  optimizelyEndUserIdCookie,
  selectedOptionValues,
  logger,
}: getNextStepV2Input): Promise<any | undefined> {
  let requestUrl = `${baseUrl}/v2/nextstep/${siteTenant}/${locale}/${experience}/${productKey}/${productVersion}?requestor=${REQUESTOR}`;
  if (optimizelyEndUserIdCookie) {
    requestUrl += `&optimizelyEndUserId=${optimizelyEndUserIdCookie}`;
  }
  if (selectedOptionValues && Object.entries(selectedOptionValues).length > 0) {
    const formattedOptionValues = Object.entries(selectedOptionValues).reduce((acc, curr) => {
      return acc + `&optionValues[${encodeURIComponent(curr[0])}]=${encodeURIComponent(curr[1])}`;
    }, "");
    requestUrl += formattedOptionValues;
  }
  try {
    const resp = await fetch(requestUrl);
    if (resp.ok) {
      const response: SiteflowResponse = await resp.json() as SiteflowResponse;
      if (response) {
        const newUrl = response.url.replace("{workId}", workId);
        return {
          nextStep: newUrl,
          predictedSteps: mapFlowToSteps(response.predictedFlow),
        };
      }
    } else {
      throw new Error(`Failed to get next step: ${resp.statusText}`);
    }
  } catch (ex) {
    logger.error(`Siteflow request failed: ${ex}`, {
      requestUrl,
      locale,
      productKey,
      productVersion,
      workId,
      experience,
      optimizelyEndUserIdCookie,
      selectedOptionValues,
    });
  }
}

function mapFlowToSteps(predictedFlow: SiteflowPredictedFlow[]): string[] {
  try {
    return predictedFlow.map((i) => i.url);
  } catch (e) {
    return [];
  }
}