import { createContext, useContext, useEffect, useState } from "react";

// @ts-ignore - server-side, getPcxtV3 is undefined
import { getPcxtV3 } from "@vp/ubik-pricing-context";

export interface PricingContext {
  pricingContextString: string;
  market: string;
  couponCode: string;
  effectiveDateTime: string;
  testIds: [];
  vatInclusive: boolean;
}

export const PricingContextContext = createContext<PricingContext | undefined>(undefined);

interface PricingContextProviderProps {
  countryCode: string;
  tenant: string;
  children: any;
}

export function PricingContextProvider({ countryCode, tenant, children }: PricingContextProviderProps) {
  const [pricingContext, setPricingContext] = useState<PricingContext>({
    pricingContextString: "",
    market: countryCode,
    couponCode: "",
    effectiveDateTime: "",
    testIds: [],
    vatInclusive: true,
  });

  // Event to process the initialized pricing context
  useEffect(() => {
    function refreshPricingContext(): void {
      const pcxtv3 = getPcxtV3();
      const pricingContext = pcxtv3?.getPricingContext();
      const pricingContextString = pcxtv3?.getEncodedContextString();

      if (!pricingContext) {
        setPricingContext({
          pricingContextString: "",
          market: countryCode,
          couponCode: "",
          effectiveDateTime: "",
          testIds: [],
          vatInclusive: true,
        });
      } else {
        const newContext = {
          ...pricingContext,
          pricingContextString,
        }

        setPricingContext(newContext);
      }
    }

    const eventsToRefreshPricingContext = [
        "PCXT-V3-VatInclusivityChanged",
        "PCXT-V3-CouponCodeChanged",
        "PCXT-V3-ContextChanged",
        "PCXT-V3-EffectiveDateTimeChanged",
    ];

    // Register event listeners. If anything changes, we want to update the context.
    eventsToRefreshPricingContext.forEach((event) =>
        (globalThis as any).document.addEventListener(event, refreshPricingContext)
    );

    // returns a cleanup function to remove the listeners.
    return () => {
        eventsToRefreshPricingContext.forEach((event) =>
            (globalThis as any).document.removeEventListener(event, refreshPricingContext)
    );
    };
  }, []);

  return (
    <PricingContextContext.Provider value={pricingContext}>
      {children}
    </PricingContextContext.Provider>
  );
};

export function usePricingContext(): PricingContext {
  const ctx = useContext(PricingContextContext);
  if (!ctx) {
    throw new Error("usePricingContext should be used within a descendent of the PricingContextProvider element");
  }
  return ctx;
}