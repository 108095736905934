import { V2 } from "@vp/wrangler-data-source";
import { type DifferentialPricingResponse, fetchDifferentialPricing } from "../../pricing/fetchDifferentialPricing";
import { mapWranglerAttributeToOption } from "./mapWranglerAttributeToOption";
import { readPricesFromChoiceGroups } from "../../pricing/readPricesFromChoiceGroups";
import { mergePricingDataIntoOptions } from "./mergePricingDataIntoOptions";
import { ProductOptionsDetails } from "./ProductOptionsProvider";
import { getCountryFromLocale } from "../../utilities/localeUtilities";
import { getMinimumQuantity } from "./getMinimumQuantity";
import { Logger } from "@vp/ubik-logging";

export async function mapWranglerAttributesToPoui(
    wranglerSelectedAttributes: V2.SelectedAttributeWithSelectionStyle[],
    wranglerAttributes: V2.StandardConfigurationAttribute[],
    productKey: string,
    productVersion: string,
    locale: string,
    pricingContextString: string,
    vatInclusive: boolean,
    logger: Logger
): Promise<ProductOptionsDetails> {
    const selectedAttributes = Object.fromEntries((wranglerSelectedAttributes ?? []).map(({ key, value }) => [key, value]));
    const options = [];
    const market = getCountryFromLocale(locale)

    const opts = getProductOptions(wranglerAttributes)

    for (const attribute of wranglerAttributes ?? []) {
        // Penalty for awaiting here is only paid on first call, so no reason to parallelize
        const mappedOption = await mapWranglerAttributeToOption(productKey, productVersion, market, attribute, selectedAttributes);
        options.push(mappedOption);
    }

    let diffPricing: DifferentialPricingResponse

    try {
        diffPricing = await fetchDifferentialPricing({
            productKey,
            productVersion,
            selectedAttributes,
            pricingContextString,
            productModel: opts,
            merchandisedOptions: Object.keys(opts),
            quantity: selectedAttributes.Quantity ?? getMinimumQuantity(wranglerAttributes)
        })
    } catch (error) {
        logger.error(error, "Failed to fetch differential pricing")

        diffPricing = {
            currency: "USD",
            fractionDigits: 2,
            choiceGroups: {},
        }
    }

    const pricingDetails = readPricesFromChoiceGroups(diffPricing, vatInclusive)
    mergePricingDataIntoOptions(options, pricingDetails)

    const productOptionsDetails = {
        currency: diffPricing.currency,
        fractionDigits: diffPricing.fractionDigits,
        productOptions: options,
    }

    return productOptionsDetails
}

function getProductOptions(attributes: V2.StandardConfigurationAttribute[]): Record<string, string[]> {
    const result: Record<string, string[]> = {}
    
    for (const attr of attributes) {
        result[attr.key] = attr.values.map(v => v.key)
    }

    return result
}
