import { IdentityContext, IdentityProvider, LoggerProvider, useLogger, useStyles, useSwanFontKeys, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import { WranglerDataSourceFromWork } from '../wds/WranglerDataSourceFromWork'
import { PostDesignConfig } from './PostDesignConfig'
import { SWAN_STYLE_KEY_MAP, SwanFontNameEnum } from '@vp/swan'
import { WorkEntityProvider } from '../providers/WorkEntityProvider'
import { SiteflowProvider } from '../providers/SiteflowProvider'
import { type ServerSideData, ServerSideDataProvider } from '../providers/ServerSideDataProvider'
import { ProductSpecificPdcDataProvider } from '../providers/ProductSpecificPdcDataProvider'
import { stylesheet as priceBarStylesheet } from "../styles/priceBar.scss";
import { stylesheet as shippingCalculatorStylesheet } from "../styles/shippingCalculator.scss";
import { getCountryFromLocale } from '../utilities/localeUtilities'
import { PricingContextProvider } from '../providers/PricingContextProvider'

export const Fragment = (props: ServerSideData) => {
  useStyles(priceBarStylesheet);
  useStyles(shippingCalculatorStylesheet);
  const logger = useLogger()
  const userContext = useUserContext()
  logger.log('Rendering fragment for a locale', userContext.locale)
  
  useSwanStyleKeys(Object.keys(SWAN_STYLE_KEY_MAP) as (keyof typeof SWAN_STYLE_KEY_MAP)[])
  useSwanFontKeys([
    SwanFontNameEnum.GraphikRegular,
    SwanFontNameEnum.GraphikMedium,
    SwanFontNameEnum.Tiempos,
    SwanFontNameEnum.TiemposRegular
  ])

  const workId = userContext.queryStrings?.['workid']

  // We'll try to get the auth token from the identity provider, but if it's not available,
  // we'll use the one from the query string.
  const overrideAuthToken = userContext.queryStrings?.['authtoken']

  const createAuthConfig = (culture: string, isDevelopment = false) => ({
    culture,
    developmentMode: isDevelopment,
    options: {
      requireSession: true,
      customText: 'Sign in to see your account',
    },
  })

  return (
    <LoggerProvider>
      <IdentityProvider auth={createAuthConfig(userContext.locale ?? 'en-us')}>
        <IdentityContext.Consumer>
          { (ctx) =>  {
              const ctxAuthToken = ctx?.identity?.authorizationHeader
              const countryCode = userContext.locale ? getCountryFromLocale(userContext.locale) : 'US'
              const tenant = userContext.tenant ?? 'vistaprint'


              // If the token was provided on the query string, we'll prefer that, otherwise,
              // we'll use the one given to us by the auth context.
              const authTokenToUse = overrideAuthToken ?? ctxAuthToken ?? ""

              return (
                <PricingContextProvider countryCode={countryCode} tenant={tenant}>
                  <ServerSideDataProvider {...props}>
                    <WorkEntityProvider workId={workId ?? ""} authToken={authTokenToUse}>
                      <WranglerDataSourceFromWork authToken={authTokenToUse}>
                        <ProductSpecificPdcDataProvider>
                          <SiteflowProvider>
                            <PostDesignConfig />
                          </SiteflowProvider>
                        </ProductSpecificPdcDataProvider>
                      </WranglerDataSourceFromWork>
                    </WorkEntityProvider>
                  </ServerSideDataProvider>
                </PricingContextProvider>
              )
            }
          }
        </IdentityContext.Consumer>
      </IdentityProvider>
    </LoggerProvider>
  )
}
