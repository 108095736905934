import React from "react";
import { useUserContext } from "@vp/ubik-context";
import {
  ShippingCalculator,
  Locale as ShippingCalculatorLocale,
  RequestorId,
} from "@vp/shipping-calc-components";
import "@vp/shipping-calc-components/style.css";
import { V2 } from "@vp/wrangler-data-source";
import { getPageName, pageCategory, pageSection, pageStage } from "../../../constants/analyticsConstants";
import { useServerSideData } from "../../../providers/ServerSideDataProvider";
import { usePricingContext } from "../../../providers/PricingContextProvider";

export const ShippingCalculatorWrapper = () => {
  const { locale } = useUserContext();
  const { data } = V2.useWranglerDataContext();
  const { cartTenant } = useServerSideData();
  const { vatInclusive } = usePricingContext();

  const mpvId = data?.selections.mpvId;
  const productKey = data?.selections.productKey;
  const productSelections = Object.fromEntries(
    (data?.selections.selectedAttributes ?? []).map(({ key, value }) => [
      key,
      value,
    ])
  );
  const productQuantity = parseInt(productSelections.Quantity);
  const productVersion = data?.selections.productVersion ? parseInt(data?.selections.productVersion) : undefined;
  
  if (!cartTenant || !productKey || !productVersion || !productQuantity) {
    return null;
  }
  return (
    <ShippingCalculator
      clientConfiguration={{
        requestor: "UBIK-PDC",
        requestorId: RequestorId.PDC,
      }}
      userConfiguration={{
        vatInclusivity: vatInclusive,
      }}
      tenantConfiguration={{
        locale: locale as ShippingCalculatorLocale,
        tenant: "vistaprint",
        tenantId: cartTenant,
      }}
      productConfiguration={{
        productKey,
        productQuantity,
        productSelections,
        productVersion,
      }}
      trackingData={{
        category: pageCategory,
        pageSection,
        pageStage,
        pageName: getPageName(mpvId),
      }}
    />
  );
};
