import { useUserContext } from "@vp/ubik-context";
import { V2 } from "@vp/wrangler-data-source";
import { useServerSideData } from "../../providers/ServerSideDataProvider";
import { Locale, PageId, BaseConfig, CouponCalloutProvider, CouponCallout } from "@vp/coupon-callout";

export const CouponCalloutWrapper = () => {
  const { locale } = useUserContext();
  const { data } = V2.useWranglerDataContext();
  const { cartTenant } = useServerSideData();

  const productKey = data?.selections.productKey;
  const productVersion = data?.selections.productVersion ? parseInt(data?.selections.productVersion) : undefined; 

  const productSelections = Object.fromEntries(
    (data?.selections.selectedAttributes ?? []).map(({ key, value }) => [
      key,
      value,
    ])
  );
  const selectedQuantity = parseInt(productSelections.Quantity);
  const selectionsWithoutQuantity = structuredClone(productSelections); 
  delete selectionsWithoutQuantity.Quantity;

  const CouponCalloutBaseConfig: BaseConfig = {
    locale: locale as Locale,
    tenant: "vistaprint",
    pageId: PageId.PDC,
    developmentMode: false,
  };


  if (!cartTenant || !productKey || !selectedQuantity) {
    return <></>;
  }
  
  return (
    <CouponCalloutProvider baseConfig={CouponCalloutBaseConfig}>
      <CouponCallout
        productId={productKey} 
        version={productVersion}
        quantity={selectedQuantity}
        selections={selectionsWithoutQuantity}
      />
    </CouponCalloutProvider>
  );
}
