import { ProductOptionModel } from "@vp/product-options-ui";
import { V2 } from "@vp/wrangler-data-source";

export function applyCompletedSelectionsToOptions(
    options: ProductOptionModel[],
    completedProduct: V2.SelectedAttributes,
    selectedAttributes: V2.SelectedAttributeWithSelectionStyle[]
): Record<string, string> {
    const selected = (selectedAttributes ?? [])
        .reduce((prev, { key, value }) => { prev[key] = value; return prev }, {} as Record<string, string>)

    for (const opt of options) {
        // Get the value from the completed product and "select" it. Note that if the option was already selected,
        // it will be overwritten here with the completed selection. They'll usually be the same, but if they
        // aren't, then it indicates that the completed option is no longer compatible with the other selections,
        // so we should choose the completed option instead.
        const val = completedProduct[opt.key]
        if (val) {
            selected[opt.key] = val
        }
    }

    return selected
}
