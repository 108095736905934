import { Logger } from "@vp/ubik-logging";

const BASE_URL = "https://merchandising-content-service-cdn.prod.merch.vpsvc.com";
const REQUESTOR = "ubik-pdc-web";

export type PdcV1EntryData = {
  showTotalDifferentialPricing: boolean;
  marketingText?: Record<string, string>;
  contentful_id: string;
  /**
   * @deprecated
   */
  productLinkedMerchandisingText?: Record<string, {
    "pdc.compareOptions.openLabel": string;
    "pdc.about.openLabel": string;
    contentful_id: string;
  }>
}

export const getPdcV1EntryData = async (mpvId: string, locale: string, logger: Logger): Promise<PdcV1EntryData | undefined> => {
  const url = new URL(`${BASE_URL}/api/v1/vistaprint/types/pagePostDesignConfig/entries/${mpvId}/cultures/${locale}`);
  url.searchParams.set('requestor', REQUESTOR);

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch MCS PDC V1 Entry data: ${url}`);
    }

    const data = (await response.json()) as PdcV1EntryData;
    return data;
  } catch (error) {
    logger.error(`Error fetching PDC V1 Entry data: ${error}`, { mpvId, locale});
  }
};
