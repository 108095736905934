import { ProductOptionListModel, ProductOptionModel } from "@vp/product-options-ui"
import { PricingDetails } from "../../pricing/readPricesFromChoiceGroups"

export function mergePricingDataIntoOptions(options: ProductOptionModel[], pricingData: Record<string, Record<string, PricingDetails>>) {
    for (const opt of options) {
        for (const choice of opt.choices as ProductOptionListModel[]) {
            const choiceKey = (choice as any).key
            if ((choice as any).key) {
                const pricing = pricingData[opt.key]?.[choiceKey]
                Object.assign(choice, pricing)                
            }
        }
    }
}
