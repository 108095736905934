import { PriceBarMainButton } from '@vp/swan';
import { V2 } from '@vp/wrangler-data-source';
import React, { useContext } from 'react';
import { IdentityContext, useLogger, useUserContext } from '@vp/ubik-context';
import { WorkEntityContext } from '../../providers/WorkEntityProvider';
import { SiteflowContext } from '../../providers/SiteflowProvider';
import { addToCartOnClick } from './utils/addToCartOnClick';
import { useServerSideData } from '../../providers/ServerSideDataProvider';

interface AddToCartButtonProps {}

const AddToCartButton: React.FC<AddToCartButtonProps> = () => {
    const logger = useLogger();
    const { ctas } = useServerSideData();
    const userContext = useUserContext();
    const workId = userContext.queryStrings?.['workid'] ?? ""
    const identity = useContext(IdentityContext);
    const identityToken = identity?.identity?.accessToken;
    const authToken = userContext?.queryStrings?.['authtoken'] ?? identityToken ?? "";

    const { data, updating } = V2.useWranglerDataContext();
    const siteflow = useContext(SiteflowContext);
    const nextStep = siteflow?.siteflowNextStep
    const work = useContext(WorkEntityContext);
    
    return (
        <PriceBarMainButton
            onClick={async (event: React.MouseEvent) => {
                event.preventDefault();

                addToCartOnClick({
                    updating,
                    authToken,
                    workId,
                    data,
                    work,
                    nextStep,
                    logger,
                });
            }}
            disabled={updating}
        >
            {ctas?.[0]?.text}
        </PriceBarMainButton>
    );
};

export default AddToCartButton;