import { normalizeAuthHeader } from "../../wds/normalizeAuthHeader"
import { WorkEntity } from "../../providers/WorkEntityProvider"
import { REQUESTOR } from "../../constants"
import { Logger } from "@vp/ubik-logging"

const baseUrl = "https://workentityservice.design.vpsvc.com"

export async function getWorkEntity(
    workId: string, 
    authToken: string,
    logger: Logger,
): Promise<WorkEntity | undefined> {
    try {
        // Given the workId, fetch the product details (selected options, document, product key/version)
        // const response = await fetch('...')
        const req = getWorkEntityRequest(workId, authToken)
        const resp = await fetch(req)
        if (!resp.ok) {
            throw new Error("HTTP " + resp.status)
        }

        return await resp.json() as WorkEntity 
    } catch (ex) {
        logger.error(`Failed to get work entity: ${ex}`, { workId });
    }

    return undefined;
}

function getWorkEntityRequest(workId: string, authToken: string): Request {
    const url = `${baseUrl}/api/v1/works/${workId}?from=${REQUESTOR}&includeHidden=true`
    const req = new Request(url, {
        headers: {
            Authorization: normalizeAuthHeader(authToken)
        }
    })

    return req
}

export async function updateWork(
    workId: string,
    work: WorkEntity,
    authToken: string,
    requestor: string,
    logger: Logger,
): Promise<void> {
    const requestUrl: string = `${baseUrl}/api/v1/works/${workId}/update?from=${requestor}`;
    const request = new Request(requestUrl, {
        method: "POST",
        headers: {
            Authorization: normalizeAuthHeader(authToken),
            "Content-Type": "application/json"
        },
        body: JSON.stringify(work)
    })
    
    try {

        const response = await fetch(request);

        if (!response.ok) {
            throw new Error(`Failed to update work: ${response.statusText}`);
        }
        
    } catch (ex) {
        logger.error(`Failed to update work entity, ${ex}`, { workId, work });
    }
}