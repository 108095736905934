import React from "react"
import { PriceBarBannerSegment, PriceBarBannerSegmentImage, PriceBarBannerSegmentText, Typography } from "@vp/swan"
import { useServerSideData } from "../../providers/ServerSideDataProvider";

export const Guarantee = () => {
  const { getLocalizedLabel, getImageHref } = useServerSideData();
  const guaranteeImage = getImageHref("pdc.image.guarantee");
  const guaranteeText = getLocalizedLabel("pdc.label.guarantee");
  return (
    <PriceBarBannerSegment>
      {guaranteeImage ? (
        <PriceBarBannerSegmentImage
          style={{ filter: "invert(100%)" }}
          src={guaranteeImage}
          alt={""}
        />
      ) : (
        <></>
      )}
      <PriceBarBannerSegmentText>
        <Typography component="span" fontSize={"small"} fontWeight="bold" textAllCaps>
          {guaranteeText}
        </Typography>
      </PriceBarBannerSegmentText>
    </PriceBarBannerSegment>
  )
}