import { Logger } from "@vp/ubik-logging";

export async function realizeDocumentUrl(dtcUrl: string, authToken: string, logger: Logger): Promise<any> {
    const config = {
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    };
    try {
        const response = await fetch(dtcUrl, config);
        if (response.ok) {
            return await response.json();
        }
        else {
            throw new Error(`Failed to realize document URL: ${response.statusText}`);
        }
    } catch (ex) {
      logger.error(`Design Transformation Realization failed, ${ex}`, { dtcUrl });
    }
  }