import { DIFFERENTIAL_REQ_BATCH_SIZE } from "../constants";

/**
 * Gets batches of choice groups, since we can only send up to 25 choice groups to the API, returned as an iterable.
 **/ 
export function* getChoiceGroupBatches(merchandisedOptions: string[], productModel: Record<string, string[]>, max: number=DIFFERENTIAL_REQ_BATCH_SIZE): Iterable<Record<string, Record<string, string>>> {
  let choiceGroups: Record<string, Record<string, string>> = {};

  let i = 0;

  for (const merchandisedOption of merchandisedOptions) {
    const values = productModel[merchandisedOption];
    for (const value of values) {
        const choiceGroupKey = serializeGroupAttributePair(merchandisedOption, value);
        if (choiceGroupKey) {
          choiceGroups[choiceGroupKey] = { [merchandisedOption]: value };

          if (++i >= max) {
              i = 0
              yield choiceGroups
              choiceGroups = {}
          }
        }
      }
  }
  
  // Get the last batch.
  if (Object.keys(choiceGroups).length > 0) {
    yield choiceGroups
  }

  return choiceGroups;
}

function serializeGroupAttributePair(key: string | undefined, value: string | undefined) : string | undefined {
  if (!key || !value) { return; }
  return `${key}:${value}`
}
