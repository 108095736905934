import { ProductOptionsProvider } from '../providers/ProductOptionsProvider/ProductOptionsProvider'
import { ProductOptions } from './product-options/ProductOptions'
import { PdcPriceBar } from './price-bar/PdcPriceBar'

export function PostDesignConfig() {
    // For now, we just show the configurator, but this is the root where other components will be addeed.
    return <ProductOptionsProvider>
        <ProductOptions/>
        <PdcPriceBar />
    </ProductOptionsProvider>
}