import { V2 } from "@vp/wrangler-data-source"
import React, { PropsWithChildren, useEffect, useState, useContext } from "react";
import { getPdcV1EntryData, PdcV1EntryData } from "../clients/merchandisingContentService/merchandisingContentService";
import { useLogger, useUserContext } from "@vp/ubik-context";

export type ProductSpecificPdcDataContextType = Pick<PdcV1EntryData, "showTotalDifferentialPricing" | "marketingText">;

export const ProductSpecificPdcDataContext = React.createContext<ProductSpecificPdcDataContextType>({
  showTotalDifferentialPricing: false,
  marketingText: {},
});

export const ProductSpecificPdcDataProvider = (props: PropsWithChildren<{}>) => {
  const { data, updating } = V2.useWranglerDataContext();
  const logger = useLogger();
  const { locale } = useUserContext();
  const [pdcData, setPdcData] = useState<ProductSpecificPdcDataContextType>({
    showTotalDifferentialPricing: false,
    marketingText: {},
  });

  useEffect(() => {
    let mounted = true;
    const getProductSpecificPdcData = async () => {
      if (data?.selections?.mpvId && !updating) {
        const pdcV1EntryData = await getPdcV1EntryData(data.selections.mpvId, locale, logger);

        if (pdcV1EntryData && mounted) {
          setPdcData({
            showTotalDifferentialPricing: pdcV1EntryData.showTotalDifferentialPricing,
            marketingText: pdcV1EntryData.marketingText,
          });
        }
      }
    };

    getProductSpecificPdcData();
  
    return () => {
      mounted = false;
    };
  }, [data, updating]);

  return (
    <ProductSpecificPdcDataContext.Provider value={{ ...pdcData }}>
      {props.children}
    </ProductSpecificPdcDataContext.Provider>
  )
};

export const useProductSpecificPdcData = () => {
  const context = useContext(ProductSpecificPdcDataContext);
  if (!context) {
    throw new Error("useProductSpecificPdcData must be used within a ProductSpecificPdcDataProvider");
  }
  return context;
};
