import { PriceBarBannerSegment, PriceBarBannerSegmentImage, PriceBarBannerSegmentText, Typography } from "@vp/swan"
import { useServerSideData } from "../../providers/ServerSideDataProvider";
import { TokenReplace } from "@vp/vp-tokenized-fragment";

export const CareHelp = () => {
  const { getLocalizedLabel, getImageHref, carePhoneNumber } = useServerSideData();
  const helpImageHref = getImageHref("pdc.image.help");
  const helpText = getLocalizedLabel("pdc.label.help");
  const templatedHelpNumber = getLocalizedLabel("pdc.label.helpNumber");

  return (
    <PriceBarBannerSegment>
      {helpImageHref ? (
        <PriceBarBannerSegmentImage style={{ filter: "invert(100%)" }} src={helpImageHref} alt={""} />
      ) : (
        <></>
      )}
      <PriceBarBannerSegmentText>
        <Typography component="span" fontSize={"small"} fontWeight="bold" textAllCaps>
          {helpText}
        </Typography>
        <Typography fontSize={"small"}>
          {templatedHelpNumber ? (
            <TokenReplace
              tokenizedText={templatedHelpNumber}
              contextVariables={{ PhoneNumber: noWrap(carePhoneNumber) }}
            />
          ) : (
            <></>
          )}
        </Typography>
      </PriceBarBannerSegmentText>
    </PriceBarBannerSegment>
  )
}

function noWrap(text: string) {
  return text.replace(/ /g, "\xa0");
}
