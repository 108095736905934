import { useUserContext } from "@vp/ubik-context";
import { ShippingExclusion } from "./ShippingExclusion";
import { ShippingCalculatorWrapper } from "./ShippingCalculatorWrapper";

const COUNTRIES_WITHOUT_SHIPPING_CALCULATOR = ["IN", "AU", "SG", "NZ"];

export const Shipping = () => {
  const { locale } = useUserContext();
  const country = locale.split("-")[1]?.toUpperCase();

  return COUNTRIES_WITHOUT_SHIPPING_CALCULATOR.includes(country) ? 
    <ShippingExclusion /> :
    <ShippingCalculatorWrapper />
}
