import { createContext, JSX, useState, PropsWithChildren, useEffect } from 'react'
import { V2 } from "@vp/wrangler-data-source"
import { getWorkEntity } from '../clients/workEntityService/workEntityService'
import { useLogger } from '@vp/ubik-context'


export type WorkEntityProviderProps = {
    workId: string
    authToken: string

    /**
     * If the Wrangler details are passed in as props, then there's no need to call the work entity service to get
     * the details. We'll use this for server-side rendering to re-fetching the data client-side
     */
    wranglerDetails?: V2.WranglerGetRequest
}

// Fill this in later with the subset of work entity properties that we actually use
/**
 * A subset of the fields returned by the work entity service
 */
export type WorkEntity = {
    product: {
        key: string
        version: string
    }
    merchandising: {
        quantity: number
        merchandisingSelections: {
            [key: string]: string
        }
    },
    design?: WorkEntityDesign
}

export type WorkEntityDesign = {
    designUrl: string
    displayUrl: string
    manufactureUrl: string
    docRefUrl: string
}

export const WorkEntityContext = createContext<WorkEntity | undefined>(undefined)

export function WorkEntityProvider(props: PropsWithChildren<WorkEntityProviderProps>): JSX.Element {
    const [ workEntity, setWorkEntity ] = useState<WorkEntity | undefined>(undefined)
    const logger = useLogger();

    if (!props.workId) {
        logger.error("WorkEntityProvider: workId is required");
    }

    useEffect(() => {
        let mounted = true
        async function fetchWork() {
            if (props.authToken && props.workId) {
                const work = await getWorkEntity(props.workId, props.authToken, logger)
                if (mounted) {
                    setWorkEntity(work)
                }
            }
        }
        fetchWork()

        return () => {
            mounted = false
        }
    }, [props.workId, props.authToken])

    return (
        <WorkEntityContext.Provider value={workEntity}>
            {props.children}
        </WorkEntityContext.Provider>
    )
}
