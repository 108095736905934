import { V2 } from "@vp/wrangler-data-source"
import { WorkEntity } from "../../providers/WorkEntityProvider"
import { REQUESTOR } from "../../constants"

export function getProductDetailsFromWork(workEntity: WorkEntity, locale: string, authToken: string): V2.WranglerGetRequest {
    const selectedAttributes = {
        ...workEntity.merchandising.merchandisingSelections,
        Quantity: workEntity.merchandising.quantity.toString() 
    }

    // We've traditionally called it a "culture", but locale is more widely used in Vista and beyond
    // We'll use "culture" when we have to, and "locale" everywhere else
    return {
        merchant: 'Vistaprint',
        culture: locale,
        productKey: workEntity.product.key,
        productVersion: parseInt(workEntity.product.version),
        selectedAttributes,
        initialSelectedAttributes: selectedAttributes,
        requestor: REQUESTOR,
        merchandisingExperience: 'Post Design Config Page',
        authToken,
    }
}