import React from "react"

interface DictionaryEntry {
  key: string;
  value: string;
}

interface CloudinaryImage {
  contentfulName: string;
  cloudinaryImage: {
    transformedCloudinaryUrl: string;
  };
}

interface Cta {
  text: string;
  url: string;
}

export type ServerSideData ={
  labels: DictionaryEntry[];
  images: CloudinaryImage[];
  ctas: Cta[];
  carePhoneNumber: string;
  cartTenant: string;
}

export type ServerSideDataProviderProps = ServerSideData;

export type ServerSideDataContextType = ServerSideData & {
  getLocalizedLabel: (labelKey: string) => string | undefined;
  getImageHref: (imageKey: string) => string | undefined;
}

export const ServerSideDataContext = React.createContext<ServerSideDataContextType>({
  labels: [],
  images: [],
  ctas: [],
  carePhoneNumber: "",
  cartTenant: "",
  getLocalizedLabel: () => undefined,
  getImageHref: () => undefined,
});

export const ServerSideDataProvider = (props: React.PropsWithChildren<ServerSideDataProviderProps>) => {
  const { children, labels, images, ...rest } = props;

  const getLocalizedLabel = (labelKey: string): string | undefined => {
    const label = labels.find((label) => label.key === labelKey)?.value;
    return label;
  };

  const getImageHref = (imageKey: string): string | undefined => {
    const url = images.find((image) => image.contentfulName === imageKey.toLowerCase())
      ?.cloudinaryImage?.transformedCloudinaryUrl;
    return url;
  };
    
  return (
    <ServerSideDataContext.Provider value={{ ...rest, images, labels, getLocalizedLabel, getImageHref }}>
      {children}
    </ServerSideDataContext.Provider>
  )
}

export const useServerSideData = () => {
  const context = React.useContext(ServerSideDataContext);
  if (!context) {
    throw new Error("useServerSideData must be used within a ServerSideDataProvider");
  }
  return context;
}
