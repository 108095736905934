import { PriceBarInfoDetails as SwanPriceBarInfoDetails } from "@vp/swan";
import { Shipping } from "./shipping/Shipping";
import { CouponCalloutWrapper } from "./CouponCalloutWrapper";

export const PriceBarInfoDetails = () => {
  return (
    <SwanPriceBarInfoDetails>
      <CouponCalloutWrapper />
      <Shipping />
    </SwanPriceBarInfoDetails>
  )
}