import { REQUESTOR } from "../constants"
import { addObjectToSearchParams } from "./addObjectToSearchParams"
import { getChoiceGroupBatches } from './getChoiceGroupBatches'

// Very naively just handle list options for now
export type ProductModel = Record<string, string[]>

export type DifferentialPriceRequest = {
    productKey: string,
    productVersion: string,
    quantity: string,
    merchandisedOptions: string[],
    selectedAttributes: Record<string, string>,
    pricingContextString: string,
    productModel: Record<string, string[]>
    couponCode?: string,
}

export type DifferentialPriceBatchRequest = {
    productKey: string,
    productVersion: string,
    quantity: string,
    selectedAttributes: Record<string, string>,
    pricingContextString: string,
    choiceGroups: Record<string, Record<string, string>>
    couponCode?: string,
}

export type DifferentialTypeResponse = {
    differentialListPrice: number,
    differentialDiscountPrice: number,
    differentialUnitListPrice: number,
    differentialUnitDiscountPrice: number,
}

export type Price = { 
    taxed: number
    untaxed: number
}

export type DifferentialPricingResponse = {
    fractionDigits: number
    currency: string
    choiceGroups: {
        [selectionKey: string]: {
            priceCalculationFailed: boolean
            failureReason: string
            totalListPrice: Price
            totalDiscountPrice: Price
            unitListPrice: Price
            unitDiscountedPrice: Price
            differentialListPrice: Price
            differentialDiscountPrice: Price
            differentialUnitListPrice: Price
            differentialUnitDiscountPrice: Price
            additionalCharges: any[]
        }
    }
}

export async function fetchDifferentialPricing(req: DifferentialPriceRequest): Promise<DifferentialPricingResponse> {
    const batches: Promise<DifferentialPricingResponse>[] = []

    for (const batch of getChoiceGroupBatches(req.merchandisedOptions, req.productModel)) {
        const batchReq = {
            ...req,
            choiceGroups: batch,
        }

        const response = fetchDifferentialPricingBatch(batchReq)
        batches.push(response)
    }

    const resolvedBatches = await Promise.all(batches)
    const response: DifferentialPricingResponse = {
        fractionDigits: resolvedBatches[0].fractionDigits,
        currency: resolvedBatches[0].currency,
        choiceGroups: {}
    }

    for (const resolvedBatch of resolvedBatches) {
        for (const [key, value] of Object.entries(resolvedBatch.choiceGroups)) {
            response.choiceGroups[key] = value
        }
    }

    return response
}

async function fetchDifferentialPricingBatch(req: DifferentialPriceBatchRequest): Promise<DifferentialPricingResponse> {
    const url = buildRequestUrl(req)

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })

        if (!response.ok) {
            throw new Error(`Failed to fetch differential pricing: ${response.status} ${response.statusText}`)
        }

        const data = await response.json()
        return data as DifferentialPricingResponse
    } catch (error) {
        console.error('Error fetching differential pricing:', error)
        throw error
    }
}

function buildRequestUrl(req: DifferentialPriceBatchRequest) {
  const {
    pricingContextString,
    productKey,
    productVersion,
    quantity,
    selectedAttributes,
    choiceGroups,
  } = req

  const url = new URL(`https://website-pricing-service.prices.cimpress.io/v4/prices/startingAt/differential`)

  url.searchParams.set('ProductKey', productKey)
  url.searchParams.set('Quantity', quantity)
  url.searchParams.set('pricingContext', pricingContextString)
  url.searchParams.set('requestor', REQUESTOR)

  if (productVersion) {
    url.searchParams.set('productVersion', productVersion)
  }

  addObjectToSearchParams("Selections", selectedAttributes, url.searchParams)
  addObjectToSearchParams("ChoiceGroups", choiceGroups, url.searchParams)

  return url.toString()
}

