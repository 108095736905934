import type { DifferentialPricingResponse, Price } from "./fetchDifferentialPricing";

export type PricingDetails = {
    totalListPrice?: number,
    totalDiscountedPrice?: number,
    unitListPrice?: number,
    unitDiscountedPrice?: number,

    differentialListPrice?: number,
    differentialDiscountPrice?: number,
    differentialUnitListPrice?: number,
    differentialUnitDiscountPrice?: number,
}

// Get the pricing details from the choice groups
export function readPricesFromChoiceGroups(
    diffPricingResponse: DifferentialPricingResponse,
    vatInclusive: boolean
): Record<string, Record<string, PricingDetails>> {
    const result: Record<string, Record<string, PricingDetails>> = {}

    for (const [selectionKey, pricingDetails] of Object.entries(diffPricingResponse.choiceGroups)) {
        const [encodedKey, encodedValue] = selectionKey.split(':')

        const key = decodeURIComponent(encodedKey)
        const value = decodeURIComponent(encodedValue)

        if (!result[key]) {
            result[key] = {}
        }

        result[key][value] = {
            totalListPrice: getPrice(pricingDetails.totalListPrice, vatInclusive),
            totalDiscountedPrice: getPrice(pricingDetails.totalDiscountPrice, vatInclusive),
            unitListPrice: getPrice(pricingDetails.unitListPrice, vatInclusive),
            unitDiscountedPrice: getPrice(pricingDetails.unitDiscountedPrice, vatInclusive),

            differentialListPrice: getPrice(pricingDetails.differentialListPrice, vatInclusive),
            differentialDiscountPrice: getPrice(pricingDetails.differentialDiscountPrice, vatInclusive),
            differentialUnitListPrice: getPrice(pricingDetails.differentialUnitListPrice, vatInclusive),
            differentialUnitDiscountPrice: getPrice(pricingDetails.differentialUnitDiscountPrice, vatInclusive),
        }
    }

    return result
}

function getPrice(price: Price, vatInclusive: boolean) {
    return vatInclusive ? price.taxed : price.untaxed
}