import { ProductOptionsSection, ProductOptionsTranslations } from '@vp/product-options-ui'
import { useUserContext } from '@vp/ubik-context'
import { V2 } from '@vp/wrangler-data-source'
import React from 'react'
import { mergeInputAndSelectedOptions } from './utils/mergeInputAndSelectedOptions'
import { useProductOptions } from '../../providers/ProductOptionsProvider'

interface ProductOptionsProps {}

export const ProductOptions: React.FC<ProductOptionsProps> = (props) => {
  const userContext = useUserContext()
 
  const { setSelections } = V2.useManagedWranglerSelectionsContext();

  const { productOptions, currency, fractionDigits} = useProductOptions();

  const handleChangeSelectionsCallback = React.useCallback(
    (inputSelections: Record<string, string>) => {
      const finalSelections = mergeInputAndSelectedOptions(inputSelections, productOptions);
      setSelections(finalSelections);
    },
    [productOptions, setSelections]
  );

  return productOptions && (
    <ProductOptionsTranslations locale={userContext.locale}>
      <ProductOptionsSection
        tenant={userContext.tenant ?? 'vistaprint'}
        productOptions={productOptions}
        priceDisplayMode="differential-total"
        currency={currency}
        fractionDigits={fractionDigits}
        locale={userContext.locale}
        onChangeSelections={handleChangeSelectionsCallback}
        uiDensityUpdate
      />
    </ProductOptionsTranslations>
  )

  
}
