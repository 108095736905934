export type NestedParameters = { [key: string]: string | NestedParameters }

export function addObjectToSearchParams(prefix: string, obj: NestedParameters, searchParams: URLSearchParams): void {
    const flattened = flattenObject(obj)
    for (const [key, value] of Object.entries(flattened)) {
        if (value !== undefined && value !== null) {
            searchParams.append(`${prefix}${key}`, value)
        }
    }
}

function flattenObject(obj: NestedParameters): Record<string, string> {
    const result: Record<string, string> = {}

    for (const [key, value] of Object.entries(obj)) {
        if (typeof value !== 'object' && value != null) {
            result[`[${key}]`] = value as string
        } else {
            const flattened = flattenObject(value as NestedParameters)
            for (const [nestedKey, nestedValue] of Object.entries(flattened)) {
                result[`[${key}]${nestedKey}`] = nestedValue
            }
        }
    }

    return result
}