import type { ProductOptionModel } from '@vp/product-options-ui'
import { V2 } from '@vp/wrangler-data-source'

import { createContext, type PropsWithChildren, useState, useEffect, useContext } from 'react'
import { usePricingContext } from '../PricingContextProvider'
import { applyCompletedSelectionsToOptions } from './applyCompletedSelectionsToOptions'
import { mapWranglerAttributesToPoui } from './mapWranglerAttributesToPoui'
import { useLogger } from '@vp/ubik-context'

export type ProductOptionsProviderProps = {
}

export type ProductOptionsDetails = {
    // It feels a little odd to include the currency and fractionDigits as part of the product options context.
    // But it's not part of the pricing context; we only get it by calling differential pricing, and it feels like overkill to introduce another provider
    currency: string
    fractionDigits: number
    productOptions: ProductOptionModel[]
}

export const ProductOptionsContext = createContext<ProductOptionsDetails | null>(null)

export function ProductOptionsProvider(props: PropsWithChildren<ProductOptionsProviderProps>) {
    const [ productOptions, setProductOptions ] = useState<ProductOptionsDetails | null>({ currency: "USD", fractionDigits: 2, productOptions: [] })
    const [ initialLoad, setInitialLoad ] = useState(true)

    const wdc = V2.useWranglerDataContext()
    const { setSelections } = V2.useManagedWranglerSelectionsContext();

    const pricingContext = usePricingContext()
    const logger = useLogger()
    
    useEffect(() => {
        let mounted = true;

        const mapWranglerOptionsToPouiOptions = async () => {
            const selections = wdc.data?.selections
            
            if (wdc.data?.attributes && selections?.productKey && selections.productVersion && wdc.context.culture) {
                // Map to the POUI shape, but also fetch differential pricing and PASTA data
                const productOptionsDetails = await mapWranglerAttributesToPoui(
                    selections.selectedAttributes ?? [],
                    wdc.data.attributes,
                    selections.productKey,
                    selections.productVersion,
                    wdc.context.culture,
                    pricingContext.pricingContextString,
                    pricingContext.vatInclusive,
                    logger
                )

                if (mounted) {
                    setProductOptions(productOptionsDetails)
                }

                // After the initial load, we want to fill in any missing selections from the completed product.
                // This will trigger a re-fetch from Wrangler
                if (initialLoad) {
                    setInitialLoad(false)
                    const selected = applyCompletedSelectionsToOptions(productOptionsDetails.productOptions, selections.completedProduct ?? {}, selections.selectedAttributes ?? [])

                    setSelections(selected)
                }
            }
        };

        mapWranglerOptionsToPouiOptions();

        return () => {
            mounted = false;
        }
    }, [wdc.data, wdc.context.culture])
    
    return (
        <ProductOptionsContext.Provider value={productOptions}>
            {props.children}
        </ProductOptionsContext.Provider>
    )
}

export const useProductOptions = () => {
    const context = useContext(ProductOptionsContext)
    if (!context) {
        throw new Error('useProductOptions must be used within a ProductOptionsProvider')
    }
    return context
}