import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { useServerSideData } from "../../../providers/ServerSideDataProvider";
import { Button, ModalDialog, ModalDialogBody, ModalDialogCloseButton, ModalDialogContent, PriceBarLink, Typography, UncustomizableMarkup } from "@vp/swan";
import { useProductSpecificPdcData } from "../../../providers/ProductSpecificPdcDataProvider";

export const ShippingExclusion = () => {
  const { getLocalizedLabel } = useServerSideData();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { marketingText } = useProductSpecificPdcData();
  const hasShippingInfo = !!marketingText?.shippingInformation;

  return hasShippingInfo ? (
    <></>
  ) : (
    <PriceBarLink tabIndex={-1} component="div" className="price-bar-shipping-link">
      <Button skin="unstyled" onClick={() => setIsModalOpen(true)} marginY="0">
        <Typography fontSize={"small"} textAlign="right" className="shipping-button">
          {getLocalizedLabel("Shipping exclusion")}
        </Typography>
      </Button>
      <ModalDialog
        isOpen={isModalOpen}
        onRequestDismiss={() => setIsModalOpen(false)}
        variant="panel-right"
        style={{ textAlign: "left" }}
      >
        <ModalDialogContent>
          <ModalDialogCloseButton accessibleText={getLocalizedLabel("pdc.vortexButton.Close")} />
          <ModalDialogBody>
            <UncustomizableMarkup>
              <ReactMarkdown>{marketingText?.shippingInformation}</ReactMarkdown>
            </UncustomizableMarkup>
          </ModalDialogBody>
        </ModalDialogContent>
      </ModalDialog>
    </PriceBarLink>
  );
}