import { V2 } from "@vp/wrangler-data-source";

export function getMinimumQuantity(attributes: V2.StandardConfigurationAttribute[]): string {
    const quantityAttr = attributes.find((attr) => attr.key === 'Quantity');
    if (!quantityAttr) {
        throw new Error("Missing Quantity attribute")
    }

    let lowest = Infinity;

    for (const value of quantityAttr.values ?? []) {
        const currentQuantity = parseInt(value.key);
        if (!isNaN(currentQuantity) && currentQuantity < lowest) {
            lowest = currentQuantity;
        }
    }

    if (lowest === Infinity) {
        throw new Error("Unable to find a lowest quantity")
    }
    
    return lowest.toString();
}